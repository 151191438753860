/* eslint-disable @typescript-eslint/no-unused-vars */
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses}  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { eachDayOfInterval, format, isSameDay, parseISO } from 'date-fns'
import { nb } from 'date-fns/locale';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import {  useNavigate, useParams} from 'react-router-dom';
import { streamStatistics } from './firebase';
import Footer from './Footer'
import Loading from './Loading'
import { getAthletes } from './utils'

const Content = ({data, names}) => {
    const daysSoFar = eachDayOfInterval({start: new Date(2022, 0, 1), end: new Date()})

    return daysSoFar.map((d) => {

        const dayData = data.filter(da => isSameDay(new Date(da.date), d))
        const dayNames = dayData.map(n => n.name)

        names.forEach(({name}) => {
            if(dayNames.includes(name)) return
            dayData.push({
                name,
                date: new Date(d).toDateString(),
                count: 0,
            })
        })

        dayData.sort((a, b) => (a.name > b.name) ? 1 : -1)
       
        return <StyledTableRow
            key={ d }
            sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
        >
            <StyledTableCell  component="th" scope="row">{  format(d, 'dd.LLL', { locale: nb }) }</StyledTableCell>
            { dayData.map((day, index) => {
                if(day)
                    return (
                        <StyledTableCell key={ index } style={ { textAlign: 'right'} } component="th" scope="row">{  day.count }</StyledTableCell>
                    )
            }) }
        </StyledTableRow>
    })
}


const getNames = (stats) => {
    const names = getAthletes(stats)

    return names.map(name => {
        return {
            name,
        }
    })
}

const Stats = () => {
    const [statistics, setStatistics] = useState([]);
    const names = getNames(statistics)
    const [isLoading, setIsLoading] = useState(true);
    const { teamId } = useParams();
    const [isSignedIn, setIsSignedIn] = useState(false)
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        }
    }, [auth]);

    useEffect(() => {
        if(!auth.currentUser) return signInAnonymously(auth)
    }, [auth])

    useEffect(() => {    
        if(!isSignedIn) return

        const unsubscribe = streamStatistics(teamId, 
            (querySnapshot) => {
                if(!querySnapshot) console.log('Fant ingen pushups')
                const pushupItems = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setStatistics(pushupItems);
                setIsLoading(false)
            },
            (error) => console.log(error)
        );
        return unsubscribe;
    }, [isSignedIn, setStatistics, teamId]);

    if(isLoading){
        return <Loading />
    }

    if(!isLoading && statistics.length === 0){
        return (<div className="App">      
            <h1>Her var det tomt gitt! </h1>
        </div>)
    }

    return (
        <div className="App">
            <TableContainer component={ Paper }>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            <StyledTableCell component="th" scope="row">Dato</StyledTableCell>
                            { names.map(d => {
                                return (
                                    <StyledTableCell key={ d.name } style={ { textAlign: 'right'} } component="th" scope="row">{ d.name }</StyledTableCell>
                                )
                            }) }
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        <Content data={ statistics } names={ names }/>
                    </TableBody>
                </Table>
            </TableContainer>
            <Footer />
        </div>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default Stats;

