/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { addTeam } from './firebase';
import Footer from './Footer';

const Welcome = () => {
    const navigate = useNavigate();

    const [isSignedIn, setIsSignedIn] = useState(false);
    const [teamId, setTeamId] = useState('');
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        }
    }, [auth]);

    useEffect(() => {
        if(!auth.currentUser) return signInAnonymously(auth)
    }, [auth])

    const onButtonClick = () => { 
        if(!isSignedIn) return     

        addTeam(teamId)
            .then(() => {
                navigate(`/${teamId}/admin`)   
            })
            .catch(e => {
                console.log(e)
            })        
    }

    return (
        <>
            <WelcomeContainer>
                <h1>Pushup-konkurranse</h1>
                <h2>Samle vennene dine og se hvor mange pushups dere klarer i løpet av 2022!</h2>
                <div>
                    <p>Når du har laget et navn på gjengen din, så kan du legge til deltagere.</p>   
                    <NameContainer >
                        <TextField
                            label="Navn på gjengen"
                            value={ teamId }
                            onChange={ e => setTeamId(e.target.value) }
                            InputLabelProps={ {
                                shrink: true,
                            } }
                        />
                        <div style={ {marginLeft: '10px', marginTop: '10px'} }>
                            <Button variant="contained" onClick={ onButtonClick }>Lag konkurranse</Button>
                        </div>
                    </NameContainer>
                    <Muscle>🦾</Muscle>
                </div>
                
            </WelcomeContainer>
            <Footer />
        </>
    )
}

const WelcomeContainer = styled.div`
   height: calc(100vh - 80px);
   padding-left: 20px;
   padding-right: 20px;
`

const Muscle = styled.div`
    margin-top: 30px;
    font-size: 10em;
`

const NameContainer = styled.div`
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
`

export default Welcome;


//firebase.initializeApp(firebaseConfig);
// import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// const uiConfig = {
//     signInFlow: 'popup',
//     signInOptions: [
//         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
//         firebase.auth.EmailAuthProvider.PROVIDER_ID,
//       
//     ],
//     callbacks: {
//         signInSuccessWithAuthResult: () => false,
//     },
// }

/*
   <LogInContainer>
                            <StyledFirebaseAuth uiConfig={ uiConfig } firebaseAuth={ firebase.auth() } />
                        </LogInContainer>

const LogOutContainer = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 30px;
    margin-right: 20px;
`

const LogOutButton = styled.button`
    margin: 0 auto;
    cursor: pointer;
`
const LogInContainer = styled.div`
    margin-top: 70px;
`

                        */
