const getAthletes = (stats) => {
    const athletes = [...new Set( stats.map(obj => obj.name)) ];
    const sortedAthletes = athletes.sort()

    return sortedAthletes
}

const athleteColors = ['green', 'orange', 'red', 'blue', 'yellow', 'black', 'white', 'pink']


export {
    getAthletes,
    athleteColors
}