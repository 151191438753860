/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import React, {useEffect, useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'
import { addAthlete, getAllAthletes } from './firebase'
import Footer from './Footer'

const Admin = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    let { teamId } = useParams();
    const auth = getAuth();
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        }
    }, [auth]);

    useEffect(() => {
        if(!auth.currentUser) return signInAnonymously(auth)
    }, [auth])

    const onButtonClick = async () => {
        if(!isSignedIn) return

        const athletes = await getAllAthletes(teamId)

        if(name === '') {
            return setErrorMessage('Du da.. Utøver må da ha et navn ;)')
        }

        if(athletes.has(name)) {
            return setErrorMessage(`Beklager, men deltager med navn ${name} er allerede lagt inn. `)
        }


        addAthlete(teamId, name)    
        navigate(`/${teamId}`)      
    }

    return (
        <>
            <AdminContainer>
                <h1>Legg til deltager</h1>

                <NameContainer >
                    <TextField
                        label="Navn"
                        value={ name }
                        onChange={ e => setName(e.target.value) }
                    />
                    <div style={ {marginLeft: '10px', marginTop: '10px'} }>
                        <Button variant="contained" onClick={ onButtonClick }>Legg til deltager</Button>
                    </div>
                    { errorMessage.length > 0 && <h4 style={ {color: 'red'} }>{ errorMessage }</h4> }
                </NameContainer>
                <Muscle>🏋️</Muscle>
            </AdminContainer>
            <Footer />
        </>
    );

}

const AdminContainer = styled.div`
   height: calc(100vh - 80px);
   padding-left: 20px;
   padding-right: 20px;
`

const Muscle = styled.div`
    margin-top: 80px;
    font-size: 200px;
`

const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export default Admin;