/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { eachDayOfInterval, format, getWeek, isSameDay, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { groupBy } from 'lodash'
import { capitalize } from 'lodash';
import React, { forwardRef, useEffect, useState }  from 'react';
import Confetti from 'react-confetti'
import DatePicker from 'react-datepicker';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import { CartesianGrid, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip , XAxis, YAxis} from 'recharts';
import styled from 'styled-components'
import { getTeamStartDate, register, streamLatest } from './firebase';
import Footer from './Footer'
import Loading from './Loading'
import './App.css'

const History = ({latest}) => {
    if(!latest) return null
    const filtered = latest.slice(Math.max(latest.length - 7, 0))
    if(filtered.length === 0) return null

    return filtered.reverse().map(f => {
        return (<tr key={ f.date }>
            <TableData>{ format(parseISO(f.date), 'EEE dd/LL', { locale: nb }) }</TableData>
            <TableData>Kl. { format(parseISO(f.date), 'HH:mm', { locale: nb }) }</TableData>
            <TableData>{ f.count }</TableData>
        </tr>)
    })

}

const Athlete = () =>  {
    let { teamId, name } = useParams();
    const navigate = useNavigate();
    const { width, height } = useWindowSize()

    const auth = getAuth();

    const [count, setCount] = useState(0);
    const [date, setDate] = useState(new Date())
    const [latest, setLatest] = useState([])
    const [teamStartDate, setTeamStartDate] = useState(null)
    const [isSignedIn, setIsSignedIn] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [showConfetti, setShowConfetti] = useState(false);

    const totalCount = latest.reduce((sum, obj) => sum + obj.count, 0)

    const obj = {
        teamId,
        name,
        date,
        count
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        }
    }, [auth]);

    useEffect(() => {
        if(!auth.currentUser) return signInAnonymously(auth)
    }, [auth])

    const onButtonClick = () => {
        if(!isSignedIn) return   
        if(latest.length > 1 && totalCount < 10000 && (totalCount + count - latest[latest.length-1].count) >= 10000)  {
            setShowConfetti(true)
            return register(JSON.stringify(obj))
        }

        register(JSON.stringify(obj)).then(() =>{
            navigate(`/${teamId}`)  
        })   

    }

    useEffect(() => {
        if(!isSignedIn) return     

        async function fetchData() {
            const startDate = await getTeamStartDate(teamId);
            setTeamStartDate(startDate)
        }
        fetchData();
    }, [isSignedIn, teamId]); 
      

    useEffect(() => {   
        if(!isSignedIn) return     

        const unsubscribe = streamLatest(teamId,       
            name,          
            (querySnapshot) => {
                if(!querySnapshot) console.log('Fant ingen pushups')
                const pushupItems = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                const sortedPushupItems = pushupItems.sort((a, b) => new Date(a.date) - new Date(b.date))
                setIsLoading(false)
                setLatest(sortedPushupItems);
            },
            (error) => console.log(error)
        );
        return unsubscribe;
    }, [isSignedIn, name, setLatest, teamId]);


    const ExampleCustomInput = forwardRef(({ onClick }, ref) => (
        <HeaderButton ref={ ref } onClick={ onClick }>
            { format(date, 'dd. LLLL', { locale: nb }) }
        </HeaderButton>
    ));


    const graphData = latest.map(l => {
        return { 
            count: l.count,
            date: format(parseISO(l.date), 'dd. LLL', { locale: nb })
        }
    })

    const getTeamDates = (teamStartDate) => {
        return eachDayOfInterval({start: new Date(teamStartDate), end: new Date()})
    }

    const datesWithWeeks = getTeamDates(teamStartDate).map((date) => {
        return { date: date,
            week: getWeek(date, {locale: 'nb', weekStartsOn: 1, firstWeekContainsDate: 3})
        }
    } )
    
    const groupedByWeek = groupBy(datesWithWeeks, 'week')

    const getWeeklyPerName = (key) => {
        return groupedByWeek[key]
            .reduce((prevVal, currVal) => {
                const push = latest.find(d => isSameDay(new Date(currVal.date), new Date(d.date)))
                const count = push ? push.count : 0
                return prevVal + count                 
            }, 0)
    }

    const weekData = Object.keys(groupedByWeek).map(key => {
        return {
            'uke': key,
            'count': getWeeklyPerName(key)
        }
    }).filter(f => f.uke !== '53')   


    const numberOfDays = eachDayOfInterval({start: new Date(teamStartDate), end: new Date()}).length
    const average = Math.floor(latest.reduce((pV, cV) => cV.count + pV, 0) / numberOfDays)
    
    if(isLoading){
        return <Loading />
    }

    return (
        <>
            <Container>
                { showConfetti && 
                    <Confetti
                        width={ width }
                        height={ height }
                    />                   
                }
                { showConfetti && 
                    <div className='congrats-container'>
                        <div className="wordart blues"><span className="text">Gratulerer med over 10 000 pushups så langt i 2022. <br></br><br></br> Helt sykt bra jobba { name }!</span></div>
                    </div>
                }
                <Header>
                    { capitalize(name) }
                </Header>
                <div>                
                    <DatePicker 
                        selected={ date }
                        onChange={ (date) => setDate(date) }
                        customInput={ <ExampleCustomInput /> }
                        maxDate={ new Date(2022, 11, 31) }
                        popperPlacement="auto"
                    />
                    <TextField
                        id="outlined-number"
                        autoComplete='off'
                        label="Totalt antall pushups i dag"
                        type="tel"
                        InputLabelProps={ {
                            shrink: true
                        } }
                        onChange={ e => setCount(parseInt(e.target.value)) }
                    />
                    <div style={ {marginTop: '10px'} }>
                        <Button variant="contained" onClick={ onButtonClick }>Push it!</Button>
                    </div>
                    <Table> 
                        <thead>
                            <tr>
                                <TableData align='left'>Dato</TableData>
                                <TableData align='left'>Tidspunkt</TableData>
                                <TableData align='left'>Antall</TableData>
                            </tr>
                        </thead>
                        <tbody>
                            <History latest={ latest }/>
                        </tbody>
                    </Table>
                    <h1>Snitt per dag: { average } </h1>
                    <div style={ { display: 'flex' , marginRight: '5%', marginLeft: '3%', marginTop: '30px', marginBottom: '15px'} }>
                        <ResponsiveContainer width={ '100%' } height={ 400 }>
                            <LineChart height={ 250 } data={ graphData } margin={ { top: 5 } }>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" padding={ { right: 10 } }/>
                                <YAxis />
                                <Tooltip />
                                <ReferenceLine 
                                    label={ { 
                                        value: 'Gjennomsnitt',
                                        position: 'insideTopLeft',
                                        fill: 'black', 
                                        fontSize: 12,
                                        fontStyle: 'italic' 
                                    } } 
                                    y={ average } 
                                    stroke="green" 
                                />
                                <Line type="linear" dataKey="count" stroke="#82ca9d" strokeWidth={ 3 } />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <h1>Ukentlig</h1>
                    <div style={ { display: 'flex' , marginRight: '5%', marginLeft: '3%', marginTop: '30px', marginBottom: '15px'} }>
                        <ResponsiveContainer width={ '100%' } height={ 400 }>
                            <LineChart height={ 250 } data={ weekData } margin={ { top: 5 } }>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="uke" padding={ { right: 10 } }/>
                                <YAxis />
                                <Tooltip />
                                <Line type="linear" dataKey="count" stroke="#82ca9d" strokeWidth={ 3 } />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
}

const Container = styled.div`
    text-align: center;
    height: calc(100% - 80px);
`

const Header = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     font-size: calc(10px + 2vmin);
`

const Table = styled.table`
    margin-top: 20px;
     margin-left: auto;
     margin-right: auto;
     border-collapse: collapse;
     border: 1px solid black;
     padding: 5px;
`

const TableData = styled.td`
    border: 1px solid black;
    padding: 5px;
`

const HeaderButton = styled.div`
     display: inline-block;
     padding: 10px;
     margin-top: 20px;
     margin-bottom: 40px;
     font-size: 30px;
     font-weight: bold;
     box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
     cursor: pointer;
     text-align: center;
`

export default Athlete;