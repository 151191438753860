import { css } from '@emotion/react';
import React from 'react'
import {ClockLoader} from 'react-spinners';

const override = () => css`
  display: block;
  margin: 0 auto;
  margin-top: 20%;
  border-color: 'black';
`;

const Loading = () => {
    return (<div className="App">      
        <ClockLoader color={ 'black' } css={ override() } size={ 150 } />
    </div>)
}

export default Loading