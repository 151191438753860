/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import { eachDayOfInterval, format, getWeek, isAfter, isSameDay, subDays } from 'date-fns'
import { groupBy } from 'lodash'
import React from 'react';
import { Bar, BarChart, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { athleteColors, getAthletes } from './utils';


const getTeamDates = (teamStartDate) => {
    return eachDayOfInterval({start: new Date(teamStartDate), end: new Date()})
}

const getAthletesData = (data, relevantDate, teamStartDate) => {
    const list = data.map(athlete => {
        return {
            [athlete.name]: getAccumulatedValues(data.find(d => d.name === athlete.name).pushups, relevantDate, teamStartDate)
        }
    })
    return list
}

const getAccumulatedValues = (pushups, relevantDate, teamStartDate) => {
    if(!pushups) return 0 

    const startDate = new Date(teamStartDate)
    const startDateWithOffset = subDays(startDate, 1)

    const relevantDates = eachDayOfInterval({start: startDateWithOffset, end: new Date(relevantDate)})
    
    const sortedPushupDates = pushups.sort((a,b) => new Date(a.date) - new Date(b.date))

    return relevantDates.map(date => {
        const hit = sortedPushupDates.find(d => isSameDay(new Date(date), new Date(d.date)))
        return hit ? hit.count : 0
    }).reduce((a, b) => a + b, 0)
}

const getGraphData = (data, teamStartDate) => {
    const teamDates = getTeamDates(teamStartDate)

    return teamDates.map((relevantDate, index) => {
        const athletesData = getAthletesData(data, relevantDate, teamStartDate)

        const result = {
            'date': format(new Date(relevantDate), 'dd.MMM'),
            'Avg10k': 27.397260274 * (index + 1),
            'Avg15k': 41.095 * (index + 1),
            'Avg20k': 54.79 * (index + 1),
            'Avg25k': 68.49 * (index + 1),
        }

        athletesData.forEach(d => {
            result[Object.keys(d)[0]] = d[Object.keys(d)[0]]
        })
        return result
    })     
}



const Graph = ({data, teamStartDate}) => {
    if(data.length === 0 || teamStartDate === undefined) return null

    const graphData = getGraphData(data, teamStartDate)

    const datesWithWeeks = getTeamDates(teamStartDate).map((date) => {
        return { date: date,
            week: getWeek(date, {locale: 'nb', weekStartsOn: 1, firstWeekContainsDate: 3})
        }
    } )
    
    const groupedByWeek = groupBy(datesWithWeeks, 'week')


    const getWeeklyPerName = (name, key) => {
        return groupedByWeek[key]
            .reduce((prevVal, currVal) => {
                const push =  data.find(d => d.name === name).pushups.find(d => isSameDay(new Date(currVal.date), new Date(d.date)))
                const count = push ? push.count : 0
                return prevVal + count                 
            }, 0)
    }
    
    const athletes = getAthletes(data)  
    const weekly = Object.keys(groupedByWeek).map(key => {
        const athData = athletes.map(athleteName => {
            return {
                [athleteName]: getWeeklyPerName(athleteName, key)
            }
        })

        const we = {
            'Uke': key,
        }

        athData.forEach(d => {
            we[Object.keys(d)[0]] = d[Object.keys(d)[0]]
        })

        return we
    }).filter(f => f.Uke !== '53')   

    const getLineChartNames = athletes.map((athleteName, index) => <Line key={ index } type="linear" dataKey={ athleteName } stroke={ athleteColors[index] } dot={ false }  strokeWidth={ 2 } />)
    const getBarChartNames = athletes.map((athleteName, index) => <Bar  key={ index } dataKey={ athleteName } fill={ athleteColors[index] } barSize={ 4 } />)
    
    if(isAfter(new Date(teamStartDate), new Date(2022,1,1))){
        return (
            <>
                <div style={ { display: 'flex' , width: '100%', height: '90%', flexWrap: 'wrap'} }>
                    { athletes.length < 9 && 
                        <>
                
                            <ResponsiveContainer width={ '100%' } height={ 400 }>
                                <LineChart data={ graphData } margin={ { top: 50, right: 20} }>
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Legend verticalAlign="top" height={ 36 } iconType='diamond'/>
                                    { getLineChartNames }
                                </LineChart>     
                            </ResponsiveContainer>
                            <ResponsiveContainer width={ '100%' } height={ 400 }>
                                <BarChart 
                                    height={ 300 } 
                                    data={ weekly }
                                    margin={ { top: 50, right: 20} }>
                                    <XAxis dataKey="Uke" />
                                    <YAxis />
                                    <Legend verticalAlign="top" height={ 36 } iconType='diamond'/>
                                    { getBarChartNames }
                                </BarChart> 
                            </ResponsiveContainer>
                        </>
                    }
                    <ResponsiveContainer width={ '100%' } height={ 400 }>
                        <BarChart  
                            height={ 300 } 
                            data={ data } 
                            layout="vertical"
                            margin={ { top: 50, right: 20, left: 10} } >
                            <XAxis type="number" domain={ [0, 'dataMax + 450'] } interval={ 0 }/>
                            <YAxis dataKey="name" type="category" />
                            <Bar dataKey="total" fill="blue" barSize={ 30 }/>                      
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </>
        )
    }

    return (
        <>
            <div style={ { display: 'flex' , width: '100%', height: '90%', flexWrap: 'wrap'} }>
                <ResponsiveContainer width={ '100%' } height={ 400 }>
                    <LineChart data={ graphData } margin={ { top: 50, right: 20} }>
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Legend verticalAlign="top" height={ 36 } iconType='diamond'/>
                        { getLineChartNames }
                    </LineChart>     
                </ResponsiveContainer>
                <ResponsiveContainer width={ '100%' } height={ 400 }>
                    <BarChart 
                        height={ 300 } 
                        data={ weekly }
                        margin={ { top: 50, right: 20} }>
                        <XAxis dataKey="Uke" />
                        <YAxis />
                        <Legend verticalAlign="top" height={ 36 } iconType='diamond'/>
                        { getBarChartNames }
                    </BarChart> 
                </ResponsiveContainer>
                <ResponsiveContainer width={ '100%' } height={ 400 }>
                    <BarChart  
                        height={ 300 } 
                        data={ data } 
                        layout="vertical"
                        margin={ { top: 50, left: 40, right: 20} } >
                        <XAxis type="number" domain={ [0, 'dataMax + 450'] } interval={ 0 }/>
                        <YAxis dataKey="name" type="category" />
                        <Bar dataKey="total" fill="blue" barSize={ 30 }/>
                        <ReferenceLine 
                            label={ 
                                { value: '10k',
                                    position: 'top',
                                    fill: 'black', 
                                    fontSize: 16,
                                    fontWeight: 'bold'   } 
                            } 
                            x={ graphData[graphData.length-1].Avg10k } 
                            stroke='black' 
                            strokeWidth={ 2 } />
                        <ReferenceLine 
                            label={ 
                                { value: `${Math.floor(graphData[graphData.length-1].Avg10k)}`,
                                    position: 'insideTopLeft',
                                    fill: 'black', 
                                    fontSize: 12,
                                    fontStyle: 'italic' } 
                            } 
                            x={ graphData[graphData.length-1].Avg10k }
                            strokeWidth={ 0 } />
                        <ReferenceLine 
                            label={ 
                                { value: '15k',
                                    position: 'top',
                                    fill: 'black', 
                                    fontSize: 16,
                                    fontWeight: 'bold'  } 
                            } 
                            x={ graphData[graphData.length-1].Avg15k } 
                            stroke='black' 
                            strokeWidth={ 2 } />
                        <ReferenceLine 
                            label={ 
                                { value: `${Math.floor(graphData[graphData.length-1].Avg15k)}`,
                                    position: 'insideTopLeft',
                                    fill: 'black', 
                                    fontSize: 12,
                                    fontStyle: 'italic' } 
                            } 
                            x={ graphData[graphData.length-1].Avg15k } 
                            strokeWidth={ 0 }/>
                        <ReferenceLine 
                            label={ 
                                { value: '20k',
                                    position: 'top',
                                    fill: 'black', 
                                    fontSize: 16,
                                    fontWeight: 'bold'   } 
                            } 
                            x={ graphData[graphData.length-1].Avg20k } 
                            stroke='black' 
                            strokeWidth={ 2 } />
                        <ReferenceLine 
                            label={ 
                                { value: `${Math.floor(graphData[graphData.length-1].Avg20k)}`,
                                    position: 'insideTopLeft',
                                    fill: 'black', 
                                    fontSize: 12,
                                    fontStyle: 'italic' } 
                            } 
                            x={ graphData[graphData.length-1].Avg20k } 
                            strokeWidth={ 0 }/>
                        <ReferenceLine 
                            label={ 
                                { value: '25k',
                                    position: 'top',
                                    fill: 'black', 
                                    fontSize: 16,
                                    fontWeight: 'bold'   } 
                            } 
                            x={ graphData[graphData.length-1].Avg25k } 
                            stroke='black' 
                            strokeWidth={ 2 } />
                        <ReferenceLine 
                            label={ 
                                { value: `${Math.floor(graphData[graphData.length-1].Avg25k)}`,
                                    position: 'insideTopLeft',
                                    fill: 'black', 
                                    fontSize: 12,
                                    fontStyle: 'italic' } 
                            } 
                            x={ graphData[graphData.length-1].Avg25k } 
                            strokeWidth={ 0 }/>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </>

    )
};

export default Graph;
