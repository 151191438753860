/* eslint-disable @typescript-eslint/no-unused-vars */
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses}  from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { addDays, eachDayOfInterval, format, getYear, isSameDay } from 'date-fns'
import { nb } from 'date-fns/locale';
import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { capitalize } from 'lodash'
import React, {useEffect, useState} from 'react';
import { Link, useNavigate, useParams} from 'react-router-dom';
import { getTeamStartDate, streamStatistics } from './firebase';
import Footer from './Footer'
import Graph from './Graph'
import Loading from './Loading'
import { Topscore } from './Topscore';
import { getAthletes } from './utils'


const getAmbitionString = (data) => {
    if(data.total >= 10000 && getYear(data.when5k) === 2022) return <StyledTableCell style={ {whiteSpace: 'nowrap'} } align="right">{ format(data.when20k, 'dd. LLL', { locale: nb }) } <sup>20k</sup> </StyledTableCell>
    if(data.total >= 5000  && getYear(data.when5k) === 2022) return <StyledTableCell style={ {whiteSpace: 'nowrap'} } align="right">{ format(data.when10k, 'dd. LLL', { locale: nb }) } <sup>10k</sup> </StyledTableCell>
    if(data.total >= 100 && getYear(data.when5k) === 2022) return <StyledTableCell style={ {whiteSpace: 'nowrap'} }  align="right">{ format(data.when5k, 'dd. LLL', { locale: nb }) } <sup>5k</sup> </StyledTableCell>
    
    return <StyledTableCell style={ {whiteSpace: 'nowrap'} } align="right">-</StyledTableCell>
}

const Content = ({data }) => {
    const { teamId } = useParams();

    const showColumn = teamId !== 'Bekk'
    const onlyBekk = teamId === 'Bekk'

    return data
        .sort((a, b) => b.total - a.total)
        .map((d, index) => {
            const daysSoFar = eachDayOfInterval({start: new Date(2022, 0, 1), end: new Date()}).length
            const endOfYear = Math.round((d.total / daysSoFar) * 365)

            return <StyledTableRow
                key={ d.name }
                sx={ { '&:last-child td, &:last-child th': { border: 0 } } }
            >
                { onlyBekk && <StyledTableCell style={ {width: 0} }>{ index + 1 }</StyledTableCell> }
                <StyledTableCell style={ {whiteSpace: 'nowrap'} } component="th" scope="row"><Link style={ {color: 'black', textDecoration: 'none'} } to={ `athlete/${d.name}` }>{ `${capitalize(d.name)}${getNumberOfStars(d.total)}` }</Link></StyledTableCell>
                <StyledTableCell align="right">{ d.today }</StyledTableCell>
                <StyledTableCell align="right">{ d.total.toLocaleString('nb') }</StyledTableCell>
                { showColumn && <StyledTableCell align="right">{ endOfYear.toLocaleString('nb')  }</StyledTableCell> }
                { showColumn && getAmbitionString(d) }
            </StyledTableRow>
        })
}

const getNumberOfStars = (total) => {
    return ' 🎖'.repeat(parseInt(total/10000))
}

const convertStats = (stats) => {
    const names = getAthletes(stats)
    return names.map(name => {
        const t = stats.find(s => s.name === name && isSameDay(new Date(s.date), new Date()))
        const total = stats.reduce((prev, curr) => (curr.name === name) ? prev + curr.count : prev, 0);

        const numberOfDays = eachDayOfInterval({start: new Date(2022, 0, 1), end: new Date()}).length
        const average = Math.ceil(total / numberOfDays)


        const daysLeftTo5k = Math.floor((5000 - total) / average)
        const when5k = daysLeftTo5k !== Infinity ? addDays(new Date(), daysLeftTo5k) : 0

        const daysLeftTo10k = Math.floor((10000 - total) / average)
        const when10k = daysLeftTo10k !== Infinity ? addDays(new Date(), daysLeftTo10k) : 0
        
        const daysLeftTo20k = Math.floor((20000 - total) / average)
        const when20k = daysLeftTo20k !== Infinity ? addDays(new Date(), daysLeftTo20k) : 0


        return {
            name,
            today: t ? t.count : 0,
            total,
            pushups: stats.filter(s => s.name === name),
            when5k,
            when10k,
            when20k
        }
    })
}

const Home = () => {
    const [statistics, setStatistics] = useState([]);
    const convertedStats = convertStats(statistics)
    const [isLoading, setIsLoading] = useState(true);
    const { teamId } = useParams();
    const navigate = useNavigate()
    const [teamStartDate, setTeamStartDate] = useState(undefined)
    const [isSignedIn, setIsSignedIn] = useState(false)
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        }
    }, [auth]);

    useEffect(() => {
        if(!auth.currentUser) return signInAnonymously(auth)
    }, [auth])

    useEffect(() => {
        if(!isSignedIn) return

        async function fetchData() {
            const startDate = await getTeamStartDate(teamId);
            setTeamStartDate(startDate)
        }
        fetchData();
    }, [isSignedIn, teamId]); 

    useEffect(() => {    
        if(!isSignedIn) return

        const unsubscribe = streamStatistics(teamId, 
            (querySnapshot) => {
                if(!querySnapshot) console.log('Fant ingen pushups')
                const pushupItems = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
                setStatistics(pushupItems);
                setIsLoading(false)
            },
            (error) => console.log(error)
        );
        return unsubscribe;
    }, [isSignedIn, setStatistics, teamId]);

    if(isLoading){
        return <Loading />
    }

    if(!isLoading && statistics.length === 0){
        return (<div className="App">      
            <h1>Her var det tomt gitt! </h1>
        </div>)
    }

    const showColumn = teamId !== 'Bekk'
    const onlyBekk = teamId === 'Bekk'

    return (
        <div className="App">
            <TableContainer component={ Paper }>
                <Table>
                    <TableHead>
                        <TableRow>
                            { onlyBekk && <StyledTableCell></StyledTableCell> }
                            <StyledTableCell>Navn</StyledTableCell>
                            <StyledTableCell style={ {whiteSpace: 'nowrap'} } align="right">Idag</StyledTableCell>
                            <StyledTableCell align="right">Totalt</StyledTableCell>
                            { showColumn && <StyledTableCell align="right">År</StyledTableCell> }
                            { showColumn &&  <StyledTableCell align="right">🏆</StyledTableCell> }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Content data={ convertedStats } teamStartDate={ teamStartDate }/>
                    </TableBody>
                </Table>
            </TableContainer>
            <button title="Legg til flere deltagere" onClick={ () => navigate('admin') } style={ {cursor: 'pointer', float: 'left' } }>
                +
            </button>

            { showColumn && <Graph data={ convertedStats } teamStartDate={ teamStartDate } /> }
            <Topscore data={ statistics } />
            <Footer />
        </div>
    );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default Home;

