/* eslint-disable react/prop-types */
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';

import React from 'react'
import styled from 'styled-components';

export const Topscore = ({ data }) => {
    const sorted = data.sort((a, b) => b.count - a.count).slice(0, 5)

    return (
        <Container>
            <h1 style={ {fontSize: '24px', textDecoration: 'underline'} }>💪Topscore på én dag💪</h1>
            <Table>
                <TableHeader>
                    <tr>
                        <TableData>Dato</TableData>
                        <TableData>Navn</TableData>
                        <TableData>Antall</TableData>
                    </tr>
                </TableHeader>
                <tbody>
                    { sorted.map((d,key) => {
                        return (<tr key={ key }>
                            <TableData>{ format(parseISO(d.date), 'EEE dd/LL', { locale: nb }) }</TableData>
                            <TableData>{ d.name }</TableData>
                            <TableData>{ d.count }</TableData>
                        </tr>)
                    }) }
                </tbody>
            </Table>          
        </Container>
    )
}

const Container = styled.div`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
`

const Table = styled.table`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
`

const TableHeader = styled.thead`
    font-weight: bold;
`

const TableData = styled.td`
    border: 1px solid black;
    padding: 5px;
`

