import { format } from 'date-fns';
import { initializeApp } from 'firebase/app'

import { 
    addDoc, 
    collection,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    onSnapshot,
    query,
    setDoc,
    where
} from 'firebase/firestore';


const firebaseConfig = {
    apiKey: 'AIzaSyANjToZEWbIFoFHR1mz8UjtUXZcHdU9Ylg',
    authDomain: 'pushup-8ca82.firebaseapp.com',
    databaseURL: 'https://pushup-8ca82-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'pushup-8ca82',
    messagingSenderId: '929261617773',
    appId: '1:929261617773:web:6f52ad6cccab80e1b9b564'
};

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const streamStatistics = async (teamId, snapshot, error) => {
    const pushupsRef = collection(db, teamId)
    let pushupsQuery = query(pushupsRef)

    return onSnapshot(pushupsQuery, snapshot, error); 
}

const streamLatest = async (teamId, name, snapshot, error) => {
    const pushupsRef = collection(db, teamId)
    let pushupsQuery = query(pushupsRef, where('name', '==', name))

    return onSnapshot(pushupsQuery, snapshot, error)
}

const register = (body) => {
    const {teamId, name, date, count} = JSON.parse(body);
    const dateFormat = format(new Date(date), 'dd.MMM');

    return setDoc(doc(db, teamId, `${name}-${dateFormat}`), {
        name,
        date,
        count
    }, { merge: true });
}

const addAthlete = (teamId, name) => {
    const dateFormat = format(new Date(), 'dd.MMM');
    const startDate = new Date()
    startDate.setUTCHours(0,0,0,0)

    return setDoc(doc(db, teamId, `${name}-${dateFormat}`), {
        name,
        date: startDate.toISOString(),
        count: 0
    });
}

const getAllAthletes = async (teamId) => {
    const querySnapshot = await getDocs(collection(db, teamId));
    const athletes = new Set()

    await querySnapshot.forEach((doc) => {
        athletes.add(doc.id.split('-')[0])
    });

    return athletes
}

const addTeam = (teamId) => {
    return setDoc(doc(db, 'Teams', teamId), {
        name: teamId,
        created: new Date().toISOString()
    })
}

const getTeamStartDate = async (teamId) => {
    const docRef = doc(db, 'Teams', teamId);
    const docSnap = await getDoc(docRef);

    return docSnap.data().created
}

const sendFeedback = (feedback) => {
    return addDoc(collection(db, 'Feedback'), {
        messages: feedback,
        created: new Date().toISOString()
    })
}

export {
    db,
    register,
    streamStatistics,
    streamLatest,
    addAthlete,
    firebaseConfig,
    addTeam,
    getTeamStartDate,
    sendFeedback,
    getAllAthletes
};