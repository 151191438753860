import { getAuth, onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { sendFeedback } from './firebase';

const Footer = () => {
    const [feedback, setFeedback] = useState('');
    const [feedbackSent, setFeedbackSent] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, () => setIsSignedIn(true));
        return () => {
            unsubscribe();
        };
    }, [auth]);

    useEffect(() => {
        if (!auth.currentUser) return signInAnonymously(auth);
    }, [auth]);

    const onFeedbackSend = () => {
        if (!isSignedIn) return;
        sendFeedback(feedback).then(() => {
            setFeedbackSent(true);
        });
    };

    return (
        <FooterContainer>
            <p>Laget av Carl Joachim Otvik</p>
            <p>For tilbakemeldinger eller ønsker, send en melding her</p>
            { !feedbackSent && (
                <div>
                    <input onChange={ (e) => setFeedback(e.target.value) } />
                    <button onClick={ () => onFeedbackSend() }>Send</button>
                </div>
            ) }
            { feedbackSent && <p>Tusen takk for tilbakemeldingen!</p> }
        </FooterContainer>
    );
};

const FooterContainer = styled.footer`
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 12px;
  width: 100%;
  left: 0;
  bottom: 0;
  right: 0;
`;

export default Footer;
