import React  from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Admin from './Admin';
import Athlete from './Athlete';
import Home from './Home';
import './firebase';
import Stats from './Stats';
import Welcome from './Welcome';

const App = () => {
    return (
        <div className="App">    
            <Routes>
                <Route path="/" element={ <Welcome /> } />
                <Route path="/:teamId" element={ <Home /> } />
                <Route path="/:teamId/admin" element={ <Admin /> } />
                <Route path="/:teamId/athlete/:name" element={ <Athlete /> } />
                <Route path="/:teamId/stats" element={ <Stats /> } />
            </Routes>            
        </div>
    );
}

export default App;


